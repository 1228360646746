/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from 'react';
import ReactDOM from 'react-dom';
import './Style/index.css';
import App from './Components/App';
import * as serviceWorker from './serviceWorker';

serviceWorker.unregister();
ReactDOM.render(<App />, document.getElementById('root'));

