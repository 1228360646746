/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import '../../Style/SideBar.scss';
import * as config from '../../Config/config.json';

const AuthGuardB2C = (props) => {
    if (!localStorage.getItem('tokenID')) {
        let baseUrl = 'https://ctmanage.b2clogin.com/ctmanage.onmicrosoft.com';
        let path = '/oauth2/v2.0/authorize';
        let policy = 'B2C_1A_signin';
        let clientId = config.clientId;
        let nonce = 'defaultNonce';
        let redirectUri = encodeURIComponent(config.redirectUrl);
        let scope = 'openid';
        let responseType = 'id_token';
        let prompt = 'login';
        let responseMode = 'query';

        window.location.href = `${baseUrl}${path}?p=${policy}&client_id=${clientId}&nonce=${nonce}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&prompt=${prompt}&response_mode=${responseMode}`;
        return null;
    }

    return (
        props.children
    );
};

export default AuthGuardB2C;
