/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

// original swagger-ui path: swagger-ui/src/core/components/auth/auths.jsx

import React from "react"
import PropTypes from "prop-types"
import ImPropTypes from "react-immutable-proptypes"

export default class Auths extends React.Component {
    static propTypes = {
        definitions: PropTypes.object.isRequired,
        getComponent: PropTypes.func.isRequired,
        authSelectors: PropTypes.object.isRequired,
        authActions: PropTypes.object.isRequired,
        specSelectors: PropTypes.object.isRequired
    };

    constructor(props, context) {
        super(props, context);

        this.state = {}
    }

    onAuthChange = (auth) => {
        let {name} = auth;

        this.setState({[name]: auth})
    };

    submitAuth = (e) => {
        e.preventDefault();

        let {authActions} = this.props;
        authActions.authorize(this.state)
    };

    logoutClick = (e) => {
        e.preventDefault();

        let {authActions, definitions} = this.props;
        let auths = definitions.map((val, key) => {
            return key
        }).toArray();

        authActions.logout(auths)
    };

    close = (e) => {
        e.preventDefault();
        let {authActions} = this.props;

        authActions.showDefinitions(false)
    };

    render() {
        let {definitions, getComponent, authSelectors, errSelectors} = this.props;
        const AuthItem = getComponent("AuthItem");
        const Oauth2 = getComponent("oauth2", true);
        const Button = getComponent("Button");

        let authorized = authSelectors.authorized();

        let authorizedAuth = definitions.filter((definition, key) => {
            return !!authorized.get(key)
        });

        const apiKeyDefinitions = definitions.filter(schema => schema.get("type") === "apiKey");
        const oauthDefinitions = definitions.filter(schema => schema.get("type") === "oauth2");

        const apiKeyDefs = apiKeyDefinitions.map((schema, name) => {
            return <AuthItem
                key={name}
                schema={schema}
                name={name}
                getComponent={getComponent}
                onAuthChange={this.onAuthChange}
                authorized={authorized}
                errSelectors={errSelectors}
            />
        }).toArray();

        const oauthDefs = oauthDefinitions.map((schema, name) => {
            return (<div key={name}>
                <Oauth2 authorized={authorized}
                    schema={schema}
                    name={name}/>
            </div>)
        }
        ).toArray();

        return (
            <div className="auth-container">
                {
                    apiKeyDefs.length > 0 && <form onSubmit={this.submitAuth}>
                        { apiKeyDefs }
                        <div className="auth-btn-wrapper">
                            {
                                apiKeyDefinitions.size === authorizedAuth.size ?
                                    <Button className="btn modal-btn auth" onClick={this.logoutClick}>Logout</Button>
                                    : <Button type="submit" className="btn modal-btn auth authorize">Authorize</Button>
                            }
                            <Button className="btn modal-btn auth btn-done" onClick={this.close}>Close</Button>
                        </div>
                    </form>
                }

                {
                    oauthDefs.length > 0 && <div>
                        <div className="scope-def">
                            <p>Scopes are used to grant an application different levels of access to data on behalf of
                                the end user. Each API may declare one or more scopes.</p>
                            <p>API requires the following scopes. Select which ones you want to grant to Swagger UI.</p>
                        </div>
                        { oauthDefs }
                    </div>
                }

            </div>
        )
    }

    static propTypes = {
        errSelectors: PropTypes.object.isRequired,
        getComponent: PropTypes.func.isRequired,
        authSelectors: PropTypes.object.isRequired,
        specSelectors: PropTypes.object.isRequired,
        authActions: PropTypes.object.isRequired,
        definitions: ImPropTypes.iterable.isRequired
    }
}