/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from 'react';

import {Link} from "react-router-dom";
import AuthGuardB2C from "./Helpers/AuthGuardB2C";
import '../Style/APIList.scss';
import fetchDataAPI from "../Logic/FetchDataAPI";
import HeaderComponent from './HeaderComponent';

const baseUrl = 'https://dev-api.connectedtravel.com';
let path = '/api/ListAvailableSwaggers';
let code = 'ry1lm33m5QTjocNBQjNatcAC/b85hamUlpOr9BNWFvMmkDSKGsLr9g==';
const projectListPath = `${baseUrl}${path}?code=${code}`;

class ProjectsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectList: [],
            message: "Loading projects...",
        }
    }

    async componentDidMount() {
        const token = localStorage.getItem("tokenID");
        let canceled = false;
        try {
            const projects = await fetchDataAPI(projectListPath, token);

            if (canceled || !projects) {
                return;
            }
            this.setState({
                projectList: projects,
                message: "You currently don't have access to any project. Contact administrator to grant access.",
            })
        } catch (err) {
            if (err.statusCode) {
                this.props.onError(err);
            } else {
                console.error('Fetch Error :-S', err);
            }
        }
        return () => canceled = true;
    }

    render() {
        const projects = this.state.projectList.map(project =>
            <li key={project.id}>
                <Link className="project-list-link" to={`/api/${project.id}`}>
                    <div className="project-list-link-items">
                        <div className="project-list-link-item text-bold">{project.title}</div>
                        <div className="project-list-link-item text-center">{project.environment}</div>
                        <div className="project-list-link-item text-right">{project.baseUrl}</div>
                    </div>
                </Link>
            </li>
        );
        return (
            <AuthGuardB2C>
                <div>
                    <HeaderComponent normalSize={true}/>
                    <div className="api-list-wrapper">
                        <h3>Select a project</h3>
                        <ul>
                            {(projects.length > 0) ? projects : (this.state.error) ? <div>Error occurred</div> :
                                <div> {this.state.message} </div>}
                        </ul>
                    </div>
                </div>
            </AuthGuardB2C>
        );
    }
}

export default ProjectsList;