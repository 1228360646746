/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from 'react';
import {BrowserRouter, Route} from "react-router-dom";
import MainAPIPage from "./MainAPIPage";
import Login from "./Helpers/Login";
import ProjectsList from "./ProjectsList";
import {Switch} from 'react-router-dom';
import ApiCallback from './Helpers/ApiCallback';
import ErrorPage from './ErrorPage';
import ErrorHandling from '../Logic/ErrorHandling.js'
import SignUpPage from "./SignUpPage";

const WrapperSignUp=(props)=>{
    return (
        <ErrorHandling{...props} component={SignUpPage}/>);
};

export default class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" render={(props) =>
                        <ErrorHandling {...props} component={ProjectsList} />}
                    />
                    <Route exact path="/api/:apiName" render={(props) =>
                        <ErrorHandling{...props} component={MainAPIPage} />}
                    />
                    <Route path="/token/callback" component={Login}/>
                    <Route path="/token/api/callback" component={ApiCallback}/>
                    <Route path="/error" component={ErrorPage}/>
                    <Route path="/signup" component={WrapperSignUp}/>
                </Switch>
            </BrowserRouter>

        );
    }
}
