/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from 'react';
import Nav from './Nav.js'
import SwaggerContent from "./Swagger/SwaggerContent";
import AuthGuardB2C from './Helpers/AuthGuardB2C';
import Path from 'path-parser'
import fetchDataAPI from "../Logic/FetchDataAPI"
import HeaderComponent from './HeaderComponent';
import smoothScroll from "./Helpers/ScrollFunc";

//--------------------IMPORTANT: UPDATING react to version >16 has lead to bugs in the past!-------------------------------------------
// -------------------The input fields in try out functionality got broken. More info in the README------------------------------------

const localPath = new Path('/api/:id');
const baseUrl = 'https://dev-api.connectedtravel.com';
let path = '/api/SwaggerAPI';
let code = 'cpua5AE5Saowh/GaP4gI/ZPbJm1gL99pAAmwdE95z4Cep7RbuaA9MA==';
const swaggerFilePath = `${baseUrl}${path}?code=${code}`;

let observer = null;

export default class MainAPIPage extends React.Component {
    constructor() {
        super();
        this.state = {
            navItemsFiltered: [],
            navItemsAll: [],
            selectedNavItemLvl0: -1,
            selectedNavItemLvl1: -1,
            yOffsetsTags: [],
            swaggerContent: undefined,
            initialNavHeight: null,
        };
        this.setNavRef = this.setNavRef.bind(this);
    }

    async componentDidMount() {
        localStorage.removeItem("swaggerAPIKey");
        const token = localStorage.getItem("tokenID");
        const apiID = localPath.test(this.props.location.pathname).id;
        localStorage.setItem("apiId", apiID);

        let canceled = false;
        try {
            const swaggerData = await fetchDataAPI(`${swaggerFilePath}&swaggerId=${apiID}`, token);

            if (canceled || !swaggerData)
                return;

            const navItems = swaggerData.tags.map(tag => {

                return {"name": tag.name, "subItems": [],}
            });

            const keys = Object.keys(swaggerData.paths);
            keys.sort();
            keys.forEach(key => {

                const pathObj = swaggerData.paths[key];
                Object.keys(pathObj).forEach(pathObjKey => {

                    if (pathObj[pathObjKey].tags) {
                        pathObj[pathObjKey].tags.forEach(tag => {
                            for (const navItem of navItems) {
                                if (navItem.name === tag) {
                                    const subItem = {
                                        "opId": pathObj[pathObjKey].operationId,
                                        "summary": pathObj[pathObjKey].summary
                                    };
                                    navItem.subItems.push(subItem);
                                    break;
                                }
                            }
                        })
                    }
                })
            });

            navItems.sort((a, b) => a.name.localeCompare(b.name));
            swaggerData.info.description = '';

            const orderedPaths = {};
            Object.keys(swaggerData.paths).sort().forEach(function(key) {
                orderedPaths[key] = swaggerData.paths[key];
            });
            swaggerData.paths = orderedPaths;

            this.setState({
                navItemsFiltered: navItems,
                navItemsAll: navItems,
                swaggerContent: swaggerData,
            });
        } catch (err) {
            if (err.statusCode) {
                this.props.onError(err);
            } else {
                console.error('Fetch Error :-S', err);
            }
        }
        return () => canceled = true;
    };

    handleClickNavItem(elementIndexLvl0, elementIndexLvl1, value, operationId) {
        let shouldMoveUp = true;
        if (elementIndexLvl1 === 0) {
            smoothScroll.scrollTo(`operations-tag-${value.replace(" ", "_")}`);
            const element = document.getElementById(`operations-tag-${value.replace(" ", "_")}`);
            element.classList.add("hover");
            setTimeout(function() {
                element.classList.remove("hover")
            }, 480);

            if (this.state.selectedNavItemLvl0 >= elementIndexLvl0 || this.state.selectedNavItemLvl0 ===-1) {
                shouldMoveUp = false;
            }

        } else {
            shouldMoveUp=false;
            operationId = operationId.replace(new RegExp(`/`, 'g'), `-`);
            operationId = operationId.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
            if (operationId.length > 0 && operationId[operationId.length - 1] === `-`) {
                operationId = operationId.slice(0, -1);
            }
            operationId = operationId.toLowerCase();
            const operationsExpandBtn = document.getElementById(`${value.replace(" ", "_")}_operations_expand`);
            const id = `operations-${value.replace(" ", "_")}-${operationId}`
            this.scrollToWhenExpanded(operationsExpandBtn.classList.contains("opened"), operationsExpandBtn, operationsExpandBtn, id, true)
        }
        let initialHeight = this.state.initialNavHeight;
        if (this.state.initialNavHeight) {
            const moveUpAmount = this.nav.scrollHeight - this.state.initialNavHeight;
            if (shouldMoveUp) {
                this.nav.scrollTop = this.nav.scrollTop - moveUpAmount;
            }
        } else {
            initialHeight = this.nav.scrollHeight
        }
        this.setState({
            selectedNavItemLvl0: elementIndexLvl0,
            selectedNavItemLvl1: elementIndexLvl1,
            initialNavHeight: initialHeight,
        })
    }

    setNavRef(ref) {
        this.nav = ref;
    }

    //recursive function that handles he following scenarios:
    //a)endpoints are not expanded yet->expand endpoints(isFirstCall)-> expand the specific operation when endpoints expanded-> scroll to that operation when expanded
    //b)endpoints are expanded but the specific operation is not->expand the specific operation -> scroll to that operation when expanded
    //c)endpoints and the specific operation are expanded->scroll to that operation
    scrollToWhenExpanded(isAlreadyExpanded, expandElement, clickTriggerEl, id, isFirstCall) {
        if (!isAlreadyExpanded) {
            clickTriggerEl.click();
            const config = {attributes: true, childList: true, subtree: true};
            const callbackFnc = (mutationsList) => {
                for (var mutation of mutationsList) {
                    if (mutation.type === 'attributes') {
                        const operationEl = document.getElementById(id);
                        if (operationEl) {
                            const childEl = this.getChildWithClass(operationEl, `opblock-summary`);
                            if (isFirstCall) {
                                this.scrollToWhenExpanded(operationEl.classList.contains("is-open"), operationEl, childEl, id, false);
                            } else {
                                smoothScroll.scrollTo(id);
                            }
                        }
                    }
                }
            };
            if (observer) {
                observer.disconnect();
            }
            setTimeout(function() {
                observer.disconnect()
            }, 1500);
            observer = new MutationObserver(callbackFnc);
            observer.observe(expandElement, config);

        } else {
            const operationEl = document.getElementById(id);
            const childEl = this.getChildWithClass(operationEl, `opblock-summary`);
            if (isFirstCall) {
                this.scrollToWhenExpanded(operationEl.classList.contains("is-open"), operationEl, childEl, id, false);
            } else {
                smoothScroll.scrollTo(id);
            }
        }
    }

    getChildWithClass(el, className) {
        for (let i = 0; i < el.childNodes.length; i++) {
            if (el.childNodes[i].classList.contains(className)) {
                return el.childNodes[i];
            }
        }
        return null;
    }

    onFilterChange(arg) {
        this.setState({
            navItemsFiltered: this.state.navItemsAll.filter((item) => item.name.toLowerCase().indexOf(arg.toLowerCase()) !== -1),
            selectedItem: 0,
        })
    }

    render() {
        return (
            <AuthGuardB2C>
                <div>
                    <HeaderComponent mainPage/>
                    <div className="s-layout">
                        <Nav navItems={this.state.navItemsFiltered} onClick={this.handleClickNavItem.bind(this)}
                            activeNavItemIndexLvl0={this.state.selectedNavItemLvl0}
                            activeNavItemIndexLvl1={this.state.selectedNavItemLvl1}
                            updateRef={this.setNavRef}
                        />
                        <main className="s-layout__content">
                            {this.state.swaggerContent ?
                                <SwaggerContent onFilterChange={(arg) => this.onFilterChange(arg)}
                                    swaggerContent={this.state.swaggerContent}/> :
                                <div>Authenticating and checking permissions ...</div>}
                        </main>
                    </div>
                </div>
            </AuthGuardB2C>
        );
    }
}
