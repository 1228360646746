/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from "react"
import HeaderComponent from './HeaderComponent';
import './../Style/SignUp.scss';
import submitDataAPI from "../Logic/SubmitDataAPI";
import {Redirect} from "react-router-dom";

const baseUrl = 'https://dev-api.connectedtravel.com';
let path = '/api/SignUpUser';
let code = 'MH16bvIeSIyF76DOrRDJzRGVPHoOBk58S1ixJsr0QabMamf2/ZErlg==';
const apiPath = `${baseUrl}${path}?code=${code}`;
export default class SignUpPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {email: '', firstName: '', lastName: ''},
            validationMessages: [],
            redirect: false,
        };

        this.handleChangeFor = this.handleChangeFor.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeFor = (propertyName) => (event) => {
        const {user} = this.state;
        const newUser = {
            ...user,
            [propertyName]: event.target.value
        };
        this.setState({user: newUser});
    };

    async handleSubmit(event) {
        event.preventDefault();
        let validationMessages = [];
        const validationRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.state.user.email.length === 0) {
            validationMessages.push("Email is required");
        } else if (!validationRegEx.test(this.state.user.email)) {
            validationMessages.push("Invalid e-mail address");
        }
        if (this.state.user.firstName.length === 0) {
            validationMessages.push("First name is required");
        }
        if (this.state.user.lastName.length === 0) {
            validationMessages.push("Last name is required");
        }

        if (validationMessages.length > 0) {
            this.setState({validationMessages});
        } else {
            const token = localStorage.getItem("tokenID");
            const body = this.state.user;
            let canceled = false;
            try {
                const returnData = await submitDataAPI(apiPath, token, body);

                if (canceled || !returnData)
                    return;

                this.setState({redirect: true});

            } catch (err) {
                if (err.statusCode) {
                    this.props.onError(err);
                } else {
                    console.error('Fetch Error :-S', err);
                }
            }
            return () => canceled = true;
        }
        event.preventDefault();
    }

    render() {
        let validationMessages = "";
        if (this.state.validationMessages.length > 0) {
            validationMessages = this.state.validationMessages.map((msg) => <li>{msg}</li>);
        }
        const validationField = this.state.validationMessages.length > 0 ? <div className={"invalidMessage"}>
            <ul>{validationMessages}</ul>
        </div> : "";
        return (
            <div>
                {this.state.redirect?<Redirect to={"/"} />:""}
                <div className={"container"}>
                    <HeaderComponent/>
                    <div className={"centeredContent"}>
                        <h2 className={"formTitle"}>Sign up</h2>
                        <form className={"signUpForm"} onSubmit={this.handleSubmit}>
                            <p> Create a new API Management account</p>
                            <p> To complete your registration fill out the form below. </p>
                            {validationField}
                            <div className="item">
                                <p className={"inputLabel"}>Email</p>
                                <input type="text" placeholder={"Email"} value={this.state.user.email}
                                    onChange={this.handleChangeFor("email")}/>
                            </div>
                            <div className="item">
                                <p className={"inputLabel"}>First name</p>
                                <input type="text" placeholder={"First name"} value={this.state.user.firstName}
                                    onChange={this.handleChangeFor("firstName")}/>
                            </div>
                            <div className="item">
                                <p className={"inputLabel"}>Last name</p>
                                <input type="text" placeholder={"Last name"} value={this.state.user.lastName}
                                    onChange={this.handleChangeFor("lastName")}/>
                            </div>
                            <input type="submit" value="Sign up"/>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}