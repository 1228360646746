/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from 'react';
import ErrorPage from "../Components/ErrorPage";

class ErrorHandling extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            showError:false,
            err:"",
        }
        this.handleError = this.handleError.bind(this);
    }

    handleError(err) {
        this.setState({showError:true, err});
    }

    render() {
        const { component: Component, ...rest} = this.props;
        return (
            <div>
                { this.state.showError ? <ErrorPage err={this.state.err} {...rest} /> : <Component onError={this.handleError} {...rest}/> }
            </div>
        );
    }
};
export default ErrorHandling;