/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from "react";
import {Link} from "react-router-dom";
import Api from '../Images/api.svg';
import SVG from 'react-inlinesvg';
import './../Style/Header.scss';

export default class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined
        }
    }

    componentDidMount() {
        this.decodeToken();
    }

    decodeToken() {
        let token = localStorage.getItem('tokenID');

        if (token) {
            let base64Url = token.split('.')[1];
            if (base64Url) {
                let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                let parsedToken = JSON.parse(jsonPayload);
                this.setState({user: ` Hello ${parsedToken.given_name} ${parsedToken.family_name}!`})
            }
        }
    }

    render() {
        let mainPage='';
        if (this.props.mainPage) {
            mainPage = "mainPage";
        }

        return (
            <div>
                <div className="header-title-fake"> </div>
                <div className={`header-wrapper ${mainPage}`}>
                    <div className={`header-content-wrapper`}>

                        <Link to="/">
                            <div className={`header-title`} >
                                <div className={`header-api-logo`}><SVG src={Api}/></div>
                                <div className="header-text">ConnectedTravel</div>
                            </div>
                        </Link>

                        <div className="user">
                            {this.state.user ? this.state.user : ''}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}