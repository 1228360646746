/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

// original swagger-ui path: swagger-ui/src/core/containers/filter.jsx

import React from "react"
import PropTypes from "prop-types"

export default class FilterContainer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: '',
        };
    }
    static propTypes = {
        specSelectors: PropTypes.object.isRequired,
        layoutSelectors: PropTypes.object.isRequired,
        layoutActions: PropTypes.object.isRequired,
        getComponent: PropTypes.func.isRequired,
    };

    onFilterChange = (e) => {
        const {target: {value}} = e;
        const lowercaseValue=value.toLowerCase();
        this.props.layoutActions.updateFilter(lowercaseValue);
        this.setState({value:value});
    };

    render() {
        const {specSelectors, layoutSelectors, getComponent} = this.props;
        const Col = getComponent("Col");

        const isLoading = specSelectors.loadingStatus() === "loading";
        const isFailed = specSelectors.loadingStatus() === "failed";
        const filter = layoutSelectors.currentFilter();

        const inputStyle = {};
        if (isFailed) inputStyle.color = "red";
        if (isLoading) inputStyle.color = "#aaa";

        return (
            <div>
                {filter === null || filter === false ? null :
                    <div className="filter-container">
                        <Col className="filter wrapper" mobile={12}>
                            <input id="filterInput" className="operation-filter-input" placeholder="Filter by tag" type="text"
                                onChange={this.onFilterChange} value={this.state.value}
                                disabled={isLoading} style={inputStyle}/>
                        </Col>
                    </div>
                }
            </div>
        )
    }
}