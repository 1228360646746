/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

// original swagger-ui path: swagger-ui/src/core/components/operation-tag.jsx

import React from "react"
import PropTypes from "prop-types"
import ImPropTypes from "react-immutable-proptypes"
import Im from "immutable"

import cssEscape from "css.escape";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons/faAngleDown";
import {faAngleUp} from "@fortawesome/free-solid-svg-icons/faAngleUp";


const createDeepLinkPath = (str) => typeof str == "string" || str instanceof String ? str.trim().replace(/\s/g, "%20") : "";
const escapeDeepLinkPath = (str) => cssEscape(createDeepLinkPath(str).replace(/%20/g, "_"));

export default class OperationTag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showOperations: false,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    static defaultProps = {
        tagObj: Im.fromJS({}),
        tag: "",

    };

    static propTypes = {
        tagObj: ImPropTypes.map.isRequired,
        tag: PropTypes.string.isRequired,

        layoutSelectors: PropTypes.object.isRequired,
        layoutActions: PropTypes.object.isRequired,

        getConfigs: PropTypes.func.isRequired,
        getComponent: PropTypes.func.isRequired,

        children: PropTypes.array,
    };

    handleClick(isShownKey, showTag,tag) {
        const element = document.getElementById(`${tag.replace(" ", "_")}_operations_expand`);
        if (!showTag) {
            element.classList.add("opened");
        }
        else {
            element.classList.remove(("opened"));
        }
        this.props.layoutActions.show(isShownKey, !showTag);
    }

    render() {
        const {
            tagObj,
            tag,
            children,
            layoutSelectors,
            getConfigs,
            getComponent,
        } = this.props;

        let {
            docExpansion,
            deepLinking,
        } = getConfigs();

        const isDeepLinkingEnabled = deepLinking && deepLinking !== "false";

        const Collapse = getComponent("Collapse");
        const Markdown = getComponent("Markdown");
        const DeepLink = getComponent("DeepLink");

        let tagDescription = tagObj.getIn(["tagDetails", "description"], null);

        let isShownKey = ["operations-tag", tag];
        let showTag = layoutSelectors.isShown(isShownKey, docExpansion === "full" || docExpansion === "list");

        if (tagDescription && tagDescription.startsWith('#')) {
            const lines = tagDescription.split('\n');
            lines.splice(0, 1);
            tagDescription = lines.join('\n');
        }

        return (

            <div id={tag} className={showTag ? "opblock-tag-section is-open" : "opblock-tag-section"}>
                <h4
                    className={!tagDescription ? "opblock-tag no-desc" : "opblock-tag"}
                    id={isShownKey.map(v => escapeDeepLinkPath(v)).join("-")}
                    data-tag={tag}
                    data-is-open={showTag}
                >

                    <DeepLink
                        enabled={isDeepLinkingEnabled}
                        isShown={showTag}
                        path={createDeepLinkPath(tag)}
                        text={tag}/>
                </h4>


                {!tagDescription ? <small> </small> :
                    <small>
                        <Markdown source={tagDescription}/>
                    </small>
                }

                <span className="CollapseOperations"
                    onClick={() => this.handleClick(isShownKey, showTag,tag)}

                    id={`${tag.replace(" ", "_")}_operations_expand`}
                    data-tag={tag}
                    data-is-open={showTag}
                >

                    <DeepLink
                        enabled={isDeepLinkingEnabled}
                        isShown={showTag}
                        path={createDeepLinkPath(tag)}
                        text="Endpoints"
                    />

                    <button
                        className="expand-operation"
                        title={showTag ? "Collapse operation" : "Expand operation"}
                    >

                        <svg className="arrow" width="2" height="2">
                            {showTag ? <FontAwesomeIcon icon={faAngleUp}/> :
                                <FontAwesomeIcon icon={faAngleDown}/>}
                            {/*<use href={showTag ? "#large-arrow-down" : "#large-arrow"} xlinkHref={showTag ? "#large-arrow-down" : "#large-arrow"} />*/}
                        </svg>
                    </button>
                </span>

                <Collapse isOpened={showTag}>
                    <div className="upper-space-for-requests"> </div>
                    {children}
                </Collapse>


            </div>
        )
    }
}