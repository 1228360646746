/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

// original swagger-ui path: swagger-ui/src/core/oauth2-authorize.js

import { sanitizeUrl as braintreeSanitizeUrl } from "@braintree/sanitize-url"

const btoa = (str) => {
    let buffer

    if (str instanceof Buffer) {
        buffer = str
    } else {
        buffer = new Buffer(str.toString(), "utf-8")
    }
    return buffer.toString("base64")
}

function sanitizeUrl(url) {
    if (typeof url !== "string" || url === "") {
        return ""
    }
    return braintreeSanitizeUrl(url)
}

let win = {
    location: {},
    history: {},
    open: () => {
    },
    close: () => {
    },
    File: function() {
    }
};

if (typeof window !== "undefined") {
    try {
        win = window;
        const props = ["File", "Blob", "FormData"];
        for (let prop of props) {
            if (prop in window) {
                win[prop] = window[prop]
            }
        }
    } catch (e) {
        console.error(e)
    }
}

export default function authorize({auth, authActions, errActions, configs, authConfigs = {}}) {
    let {schema, scopes, name, clientId} = auth;
    let flow = schema.get("flow");
    let query = [];

    switch (flow) {
        case "password":
            authActions.authorizePassword(auth);
            return;

        case "application":
            authActions.authorizeApplication(auth);
            return;

        case "accessCode":
            query.push("response_type=code");
            break;

        case "implicit":
            query.push("response_type=token");
            break;

        case "clientCredentials":
            // OAS3
            authActions.authorizeApplication(auth);
            return;

        case "authorizationCode":
            // OAS3
            query.push("response_type=code");
            break
    }

    if (typeof clientId === "string") {
        query.push("client_id=" + encodeURIComponent(clientId))
    }

    let redirectUrl = configs.oauth2RedirectUrl;

    // todo move to parser
    if (typeof redirectUrl === "undefined") {
        errActions.newAuthErr({
            authId: name,
            source: "validation",
            level: "error",
            message: "oauth2RedirectUrl configuration is not passed. Oauth2 authorization cannot be performed."
        });
        return
    }
    query.push("redirect_uri=" + encodeURIComponent(redirectUrl));

    if (Array.isArray(scopes) && 0 < scopes.length) {
        let scopeSeparator = authConfigs.scopeSeparator || " ";

        query.push("scope=" + encodeURIComponent(scopes.join(scopeSeparator)))
    }

    let state = btoa(new Date());

    query.push("state=" + encodeURIComponent(state));

    if (typeof authConfigs.realm !== "undefined") {
        query.push("realm=" + encodeURIComponent(authConfigs.realm))
    }

    let {additionalQueryStringParams} = authConfigs;

    for (let key in additionalQueryStringParams) {
        if (typeof additionalQueryStringParams[key] !== "undefined") {
            query.push([key, additionalQueryStringParams[key]].map(encodeURIComponent).join("="))
        }
    }

    const authorizationUrl = schema.get("authorizationUrl");

    const sanitizedAuthorizationUrl = sanitizeUrl(authorizationUrl);
    let url = [sanitizedAuthorizationUrl, query.join("&")].join(authorizationUrl.indexOf("?") === -1 ? "?" : "&");

    // pass action authorizeOauth2 and authentication data through window
    // to authorize with oauth2

    let callback;
    if (flow === "implicit") {
        callback = authActions.preAuthorizeImplicit
    } else if (authConfigs.useBasicAuthenticationWithAccessCodeGrant) {
        callback = authActions.authorizeAccessCodeWithBasicAuthentication
    } else {
        callback = authActions.authorizeAccessCodeWithFormParams
    }

    win.swaggerUIRedirectOauth2 = {
        auth: auth,
        state: state,
        redirectUrl: redirectUrl,
        callback: callback,
        errCb: errActions.newAuthErr
    }

    win.open(url)
}
