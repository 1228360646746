/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

// original swagger-ui path: swagger-ui/src/core/components/auth/api-key-auth.jsx

import React from "react"
import PropTypes from "prop-types"

export default class ApiKeyAuth extends React.Component {
    static propTypes = {
        authorized: PropTypes.object,
        getComponent: PropTypes.func.isRequired,
        errSelectors: PropTypes.object.isRequired,
        schema: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func
    };

    constructor(props, context) {
        super(props, context);
        let {name, schema} = this.props;


        let value = this.getValue();
        if (name === 'apiKeyHeader' || name === 'apiKeyQuery') {
            value = localStorage.getItem("apiSubKey");
        }

        if (!value) {
            value = '';
        }

        this.state = {
            name: name,
            schema: schema,
            value: value,
        };

        if (value.length > 0) {
            this.props.onChange(this.state);
        }
    }

    getValue() {
        let {name, authorized} = this.props;
        return authorized && authorized.getIn([name, "value"])
    }

    handleChange = (e) => {
        const {onChange} = this.props;
        const value = e.target.value;
        this.setState({value: value});
        onChange && onChange({...this.state, value: value})
    };

    render() {
        let {schema, getComponent, errSelectors, name} = this.props;
        const Input = getComponent("Input");
        const Row = getComponent("Row");
        const Col = getComponent("Col");
        const AuthError = getComponent("authError");
        const Markdown = getComponent("Markdown");
        const JumpToPath = getComponent("JumpToPath", true);
        let value = this.getValue();
        let errors = errSelectors.allErrors().filter(err => err.get("authId") === name);
        return (
            <div>
                <h4>
                    <code>{name || schema.get("name")}</code>&nbsp;
                    (apiKey)
                    <JumpToPath path={["securityDefinitions", name]}/>
                </h4>
                {value && <h6>Authorized</h6>}
                <Row>
                    <Markdown source={schema.get("description")}/>
                </Row>
                <Row>
                    <p>Name: <code>{schema.get("name")}</code></p>
                </Row>
                <Row>
                    <p>In: <code>{schema.get("in")}</code></p>
                </Row>
                <Row>
                    <label>Value:</label>
                    {
                        value ? <code> ****** </code>
                            : <Col><Input type="text" onChange={this.handleChange} value={this.state.value}/></Col>
                    }
                </Row>
                {
                    errors.valueSeq().map((error, key) => {
                        return <AuthError error={error}
                            key={key}/>
                    })
                }
            </div>
        )
    }

}