/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from "react"
import HeaderComponent from './HeaderComponent';
import './../Style/ErrorPage.scss';
import {Redirect} from "react-router-dom";


export default class ErrorPage extends React.Component {

    render() {
        let errRender = "";
        if (this.props.err) {
            if (this.props.err.statusCode === 403) {
                errRender = <Redirect to={"/signup"}/>
            } else {
                errRender = <div className="error-wrapper">
                    <div>
                        <div><b>Message: {this.props.err.body.message}</b></div>
                        <div><b>Status Code: {this.props.err.statusCode}</b></div>
                        <div><b>Error Code: {this.props.err.body.errorCode}</b></div>
                    </div>
                </div>;
            }
        } else {
            errRender =
                <div className="error-wrapper">
                    <div>
                        <b>Unexpected error occurred</b>
                    </div>
                </div>;
        }
        return (
            <div>
                <HeaderComponent/>
                <div className="api-list-wrapper">
                    {errRender}
                </div>
            </div>
        );
    }
}