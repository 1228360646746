/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from 'react';
import '../../Style/SideBar.scss';
import 'swagger-ui-dist/swagger-ui.css';
import SwaggerUI from "swagger-ui";
import OperationTag from "./OperationTag.js";
import DropdownComponent from "../DropdownComponent";
import ApiKeyAuth from "./ApiKeyAuth.js";
import * as config from '../../Config/config.json';
import "../../Style/SwaggerStyleOverride.scss";
import Oauth2 from "./Oauth2";
import Auths from "./Auths";
import FilterContainer from "./FilterContainer";
import Operations from "./Operations";

const DOM_ID = "swagger-ui-mountpoint";

const baseUrl = 'https://dev-api.connectedtravel.com';
let path = '/api/ListAvailableSubscriptions';
let code = 'GNS6sWcOvJml/VfXQpPDbUVLlG4Nw9VtXK98uec0NybRHGWawhbVAA==';
const apiSubscriptionListPath = `${baseUrl}${path}?code=${code}&swaggerId=`;

path = '/api/ListAvailableClients';
code = 'aAgy0BbyzeRLd5cSOTu7t20g4PJjGflLFcYsDvymgF71VyyIlrIZYg==';
const clientIDListPath = `${baseUrl}${path}?code=${code}&swaggerId=`;

class SwaggerContent extends React.Component {
    componentDidMount() {

        const MyWrapBuiltinComponentPlugin = () => ({
            wrapComponents: {
                OperationTag: () => (props) => {
                    return <div>
                        <OperationTag {...props}/>
                    </div>
                },
                info: (Original) => (props) => {
                    return <div>
                        <Original {...props}/>
                        <div className={"AuthDropdowns"}>
                            <DropdownComponent name={"API subscription key"} storageName={"apiSubKey"}
                                apiPath={apiSubscriptionListPath}/>
                            <DropdownComponent name={"Client ID"} storageName={"clientID"} apiPath={clientIDListPath}/>
                        </div>
                        <div style={{clear: "both"}}/>
                    </div>
                },
                auths: () => (props) => {
                    return <div>
                        <Auths {...props}/>
                    </div>
                },
                apiKeyAuth: () => (props) => {
                    return <div>
                        <ApiKeyAuth {...props}/>
                    </div>
                },
                oauth2: () => (props) => {
                    return <div>
                        <Oauth2 {...props}/>
                    </div>
                },
                FilterContainer: () => (props) => {
                    return <div>
                        <FilterContainer {...props}/>
                    </div>
                },
                operations: () => (props) => {
                    return <div>
                        <Operations {...props}/>
                    </div>
                },
            }
        });

        SwaggerUI({
            dom_id: `#${DOM_ID}`,
            filter: "",
            defaultModelsExpandDepth: -1,
            spec: this.props.swaggerContent,
            docExpansion: "none",
            plugins: [
                MyWrapBuiltinComponentPlugin,
                SwaggerUI.plugins.DownloadUrl
            ],
            oauth2RedirectUrl: config.oauthAPICallback,
            oauth: {
                clientId: "your-client-id",
                clientSecret: "your-client-secret-if-required1",
                realm: "your-realms1",
                appName: "your-app-name1",
                scopeSeparator: ",",
                additionalQueryStringParams: {}
            },
            //uncomment this for alphabetically sorted tags
            //tagsSorter:"alpha",

            onComplete: () => {
                const element = document.getElementById("filterInput");
                if (element) {
                    element.addEventListener('keyup', () => this.props.onFilterChange(element.value))
                }
            }
        });
    }

    render() {
        return (
            <div id={"SwaggerUIContainer"}>
                <div id={DOM_ID}/>
            </div>
        );
    }
}

export default SwaggerContent;