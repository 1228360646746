/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import * as globalVars from '../../Logic/GlobalConsts.json';

const smoothScroll = {
    timer: null,

    stop: function() {
        clearTimeout(this.timer);
    },

    scrollTo: function(id, callback) {
        const settings = {
            duration: 500,
            easing: {
                outQuint: function(x, t, b, c, d) {
                    return c*parseFloat(((t=parseFloat(t)/parseFloat(d)-1)*t*t*t*t + 1)) + parseFloat(b);
                }
            }
        };

        let percentage;
        let startTime;
        const node = document.getElementById(id);
        const offset = window.pageYOffset;
        let targetY=0;
        let timer=null;
        if (node) {
            const yOffset = node.getBoundingClientRect().y;
            targetY = yOffset - parseInt(globalVars.headerHeight) - 4;
            startTime = Date.now();
            percentage = 0;

            if (timer) {
                clearInterval(timer);
            }
            timer = setTimeout(step, 10);
        }

        function step() {
            let yScroll;
            const elapsed = Date.now() - startTime;

            if (elapsed > settings.duration) {
                clearTimeout(timer);
            }

            percentage = elapsed / settings.duration;

            if (percentage > 1) {
                clearTimeout(timer);

                if (callback) {
                    callback();
                }
            } else {
                yScroll = settings.easing.outQuint(0, elapsed, offset, targetY, settings.duration);
                window.scrollTo(0, yScroll);
                timer = setTimeout(step, 10);
            }
        }
    }
};
export default smoothScroll;