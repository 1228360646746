/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from 'react';
import '../../Style/SideBar.scss';
import queryString from 'query-string'
import {Redirect} from "react-router-dom";

const Login = (props) => {
    const values = queryString.parse(props.location.search);
    const tokenString = (values.id_token);

    if (!tokenString) {
        return (
            <Redirect to='/error'/>
        );
    }
    localStorage.setItem("tokenID", tokenString);
    return (
        <Redirect to='/'/>
    );
};
export default Login;