/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

const submitDataAPI = async function(apiPath, token, body) {
    const response = await fetch(apiPath, {
        method: 'POST',
        headers: {
            'Authorization': token,
        },
        body: JSON.stringify({
            'email': body.email,
            'firstName': body.firstName,
            'lastName': body.lastName,
        }),
    });
    if (response.status === 200) {
        return response.json();
    } else {
        const err = {statusCode: response.status, body: await response.json()};
        throw err;
    }

};
export default submitDataAPI;