/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from 'react';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css'
import '../Style/AuthDropdown.scss';
import fetchDataAPI from "../Logic/FetchDataAPI";

class DropdownComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionItems: [],
            dropDownNames: [],
            currentSelected: null,
            itemsLoaded: false,
        };
        this.onSelect = this.onSelect.bind(this);
    }

    async componentDidMount() {
        const token = localStorage.getItem("tokenID");
        const apiID = localStorage.getItem("apiId");
        let canceled = false;
        try {
            const response = await fetchDataAPI(`${this.props.apiPath}${apiID}`, token);

            if (canceled || !response) {
                return;
            }

            const dropDownNames = response.map(item => {
                if (item.title) {
                    return {value: item.primaryKey || item.id, label: item.title};
                } else {
                    return {value: item.primaryKey || item.id, label: 'title_missing'};
                }
            });

            this.setState({
                subscriptionItems: response,
                dropDownNames,
                itemsLoaded: true,
            });

            const storedItemSelection = localStorage.getItem(this.props.storageName);
            if (localStorage.getItem(this.props.storageName)) {
                for (let i = 0; i < dropDownNames.length; i++) {
                    let compareVal = response[i].primaryKey;
                    if (!compareVal) {
                        compareVal = response[i].id;
                    }
                    if (storedItemSelection === compareVal) {
                        this.setState({
                            subscriptionItems: response,
                            dropDownNames,
                            itemsLoaded: true,
                            currentSelected: dropDownNames[i],
                        });
                        break;
                    }
                }
            }

        } catch (err) {
            console.error('Fetch Error :-S', err);
        }
        return () => canceled = true;
    };

    onSelect = (e) => {
        if (this.state.subscriptionItems.length > 0) {
            for (let i = 0; i < this.state.dropDownNames.length; i++) {
                let compareVal = this.state.subscriptionItems[i].primaryKey;
                if (!compareVal) {
                    console.log(this.state.subscriptionItems[i].id);
                    console.log(e.value);
                    compareVal = this.state.subscriptionItems[i].id;
                }
                if (e.value === compareVal) {
                    localStorage.setItem(this.props.storageName, e.value);
                    this.setState({currentSelected: this.state.dropDownNames[i]});
                    break;
                }
            }
        }
    };


    render() {
        const defaultOption = this.state.currentSelected;
        return (
            <div className={"DropdownMenu"}>
                <p>{this.props.name}</p>
                {(this.state.itemsLoaded) ?
                    <Dropdown options={this.state.dropDownNames} onChange={this.onSelect} value={defaultOption}
                        placeholder={"Select..."}/>
                    :
                    <Dropdown disabled options={this.state.dropDownNames} onChange={this.onSelect} value={defaultOption}
                        placeholder={"Items loading..."}/>
                }
            </div>
        );
    }
}

export default DropdownComponent;