/*
*  Copyright © 2016, Connected Travel, LLC – All Rights Reserved.
*
*  All information contained herein is property of Connected Travel, LLC including, but
*  not limited to, technical and intellectual concepts which may be embodied within.
*
*  Dissemination or reproduction of this material is strictly forbidden unless prior written
*  permission, via license, is obtained from Connected Travel, LLC. If permission is obtained,
*  this notice, and any other such legal notices, must remain unaltered.
*
*/

import React from 'react';
import '../Style/SideBar.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";

function Nav(props) {
    let i = 0;
    const navItems = props.navItems.map((item) => {
        let class_name = "s-sidebar__nav-text";

        if (i === props.activeNavItemIndexLvl0 && props.activeNavItemIndexLvl1 === 0) {
            class_name = class_name.concat("_selected");
        }
        const indexI = i;

        let j = 1;
        let innerItems = null;

        if (i === props.activeNavItemIndexLvl0) {
            innerItems = item.subItems.map((innerItem) => {
                let class_name_inner = "s-sidebar__inner_nav-text"
                if (i === props.activeNavItemIndexLvl0 && j === props.activeNavItemIndexLvl1) {
                    class_name_inner = class_name_inner.concat("_selected");
                }
                const indexJ = j;
                j++;

                return (
                    <a key={`${i},${j}`} className="s-sidebar__inner_nav-link"
                        onClick={() => props.onClick(indexI, indexJ, item.name, innerItem.opId)}
                    >
                        <em className={class_name_inner}>{innerItem.summary}</em>
                    </a>
                )
            })
        }
        i++;

        return (
            <li key={item.name} className="s-sidebar__nav-link">
                <a className="s-sidebar__nav-link" onClick={() => props.onClick(indexI, 0, item.name)}>
                    <em className={class_name}>{item.name}</em>
                </a>
                {innerItems}
            </li>
        );
    });

    return (
        <div className="Nav">
            <a className="s-sidebar__trigger" href="#0">
                <FontAwesomeIcon className="barsIcon" icon={faBars}/>
            </a>

            <nav className="Nav s-sidebar__nav">
                <div className={"Nav header"}> </div>
                <div
                    ref={nav => {props.updateRef(nav)}}
                    className="s-sidebar__navItems"
                >
                    <div className={"innerDiv"}>
                        <ul>
                            {navItems}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Nav;